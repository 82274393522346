$(document).ready(
    function () {

        $(document).on("click", '.config-option-features-button', function () {
            if ($('#config-option-features-modal').length) {
                $('#config-option-features-modal').modal('show');
                return false;
            }
            $.ajax({
                type: 'POST',
                url: "/ajax/config_option/show",
                data: {}
            })
                .done(function (data) {
                    if (data.html !== undefined && data.html !== null) {
                        $('#content').append(data.html);
                        $('#config-option-features-modal').modal('show');
                    }
                })
                .fail(function (data) {
                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }
                })
            return false;
        });

        $(document).on('change', '.config-option-wrapper input.config-option-checkbox', function () {
            let configIdentifier = $(this).closest('.config-option-wrapper').data('config-identifier');
            if (configIdentifier !== undefined && configIdentifier !== '') {
                if ($(this).is(':checked')) {
                    $(".config-option-wrapper[data-parent-config-identifier='" + configIdentifier + "']").removeClass('d-none');
                } else {
                    $(".config-option-wrapper[data-parent-config-identifier='" + configIdentifier + "']").each(function () {
                        let configIdentifierLevel2 = $(this).data('config-identifier');
                        if (configIdentifierLevel2 !== undefined) {
                            $(".config-option-wrapper[data-parent-config-identifier='" + configIdentifierLevel2 + "']").addClass('d-none');
                            $(".config-option-wrapper[data-parent-config-identifier='" + configIdentifierLevel2 + "'] input[type='checkbox']").prop('checked', false);
                            $(".config-option-wrapper[data-parent-config-identifier='" + configIdentifierLevel2 + "'] input[type='text']").val('');
                            $(".config-option-wrapper[data-parent-config-identifier='" + configIdentifierLevel2 + "'] input[type='number']").val('');
                        }
                    });
                    $(".config-option-wrapper[data-parent-config-identifier='" + configIdentifier + "']").addClass('d-none');
                    $(".config-option-wrapper[data-parent-config-identifier='" + configIdentifier + "'] input[type='checkbox']").prop('checked', false);
                    $(".config-option-wrapper[data-parent-config-identifier='" + configIdentifier + "'] input[type='text']").val('');
                    $(".config-option-wrapper[data-parent-config-identifier='" + configIdentifier + "'] input[type='number']").val('');
                }
            }
        });


    });