/**
 *
 * @param string message
 * @param string type
 * @param string title
 * @returns {string}
 */
function getAlertHtml(message, type, title = null) {

    var titleHtml = '';

    if (title != null && title != '') {
        titleHtml = '<strong>' + title + '</strong><br><br>';
    }

    return '<div class="alert alert-' + type + ' alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>' + titleHtml + message + '</div>';

}

/**
 *
 * @param string message
 * @param string type
 * @param string title
 * @returns {string}
 */
function getAlertTable(message, type, title = null) {

    var titleHtml = '';

    if (title != null && title != '') {
        titleHtml = '<strong>' + title + '</strong><br><br>';
    }

    return '<tr><td colspan="3"><div class="alert alert-' + type + ' alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>' + titleHtml + message + '</div></td></tr>';

}

/**
 *
 * @param string prependBeforeId
 * @param string message
 * @param string type
 * @param string title
 * @param string parent
 */
function prependAlert(prependBeforeId, message, type, title = null, parent = null) {

    if (parent != null && parent != '' && $(parent).length > 0) {
        $(".alert:not(.alert-static)", $(parent)).remove();
    }

    $(getAlertHtml(message, type, title)).prependTo($(prependBeforeId));

}

/**
 *
 * @param string appendAfterId
 * @param string message
 * @param string type
 * @param string title
 * @param string parent
 */
function appendAlert(appendAfterId, message, type, title = null, parent = null) {

    if (parent != null && parent != '' && $(parent).length > 0) {
        $(".alert:not(.alert-static)", $(parent)).remove();
    }

    $(getAlertHtml(message, type, title)).appendTo($(appendAfterId));

}

/**
 *
 * @param string insertAfterId
 * @param string message
 * @param string type
 * @param string title
 * @param string parent
 */
function inserAfterAlertTr(insertAfterId, message, type, title = null, parent = null) {

    if (parent != null && parent != '' && $(parent).length > 0) {
        $(".alert:not(.alert-static)", $(parent)).remove();
    }

    $(getAlertTable(message, type, title)).insertAfter($(insertAfterId));

}

/**
 *
 */
function popupAlert(duration = 500, type = 'success', message = '') {

    if ($("#globalPopupMessage").length > 0) {
        $("#globalPopupMessage").remove();
    }

    let bgClass = (type != 'success') ? type : 'success';
    let icon = 'check_circle_outline';
    if (type == 'danger') {
        icon = 'error';
    } else if(type == 'info'){
        bgClass = 'info';
        icon = 'error';
    }

    let messageHtml = '';
    let messageClass = '';
    if (message != '') {
        messageHtml = '<br><span id="globalPopupMessageText">' + message + '</span>';
        messageClass = ' including-message';
    }

    let popup = '<div id="globalPopupMessage" class="bg-' + bgClass + ' text-white' + messageClass + '"><i class="material-icons">' + icon + '</i>' + messageHtml + '</div>';

    $(popup).appendTo("#content");

    setTimeout(function () {
        $("#globalPopupMessage").fadeOut(500, function () {
            $("#globalPopupMessage").remove()
        });
    }, duration);

}

jQuery(document).ready(function () {

    $(document).on("click", ".markable-tr", function () {
        let hasClassBgLight = false;
        if ($(this).hasClass('bg-light')) {
            hasClassBgLight = true;
        }
        $('.markable-tr').each(function () {
            if ($(this).hasClass('bg-light')) {
                $(this).removeClass('bg-light');
            }
        });
        if (!hasClassBgLight) {
            $(this).addClass('bg-light');
        }
    });

    $(document).on("click", ".clickable-td", function () {
        window.location = $(this).closest("tr").data("href");
    });

    $(document).on("click", ".clickable-li", function () {
        window.location = $(this).closest("ul").data("href");
    });

    if ($('form.organizer-form').length) {
        $(document).on("change", "form.organizer-form input:not(.no-change), form.organizer-form select:not(.no-change), form.organizer-form textarea:not(.no-change)", function () {
            if (!$('form.organizer-form').hasClass('has-changed')) {
                $('form.organizer-form').addClass('has-changed');
            }
        });
        $(document).on("submit", "form.organizer-form", function () {
            if ($('form.organizer-form').hasClass('has-changed')) {
                $('form.organizer-form').removeClass('has-changed');
            }
        });
    }
    $(window).bind('beforeunload', function () {
        if ($('form.organizer-form').length && $('form.organizer-form').hasClass('has-changed')) {
            return 'Du har ikke lagret skjema - You have unsaved changes';
        }
    });

});


/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
Number.prototype.format = function (n, x, s, c) {
    let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

/**
 * Check if browser is mobile
 * @returns {boolean}
 */
function isMobile() {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        return true;
    }
    return false;
}