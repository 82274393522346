function highchartsBarChart(primalData, graphId) {
    let graph = $("#" + graphId);

    Highcharts.chart(graphId, {
        chart: {type: 'column', styledMode: true, showAxes: true},
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        'viewFullscreen',
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                        'separator',
                        'downloadCSV',
                        'downloadXLS'
                    ]
                }
            }
        },
        title: false,
        xAxis: {
            categories: primalData.date,
            labels: {
                formatter: function () {
                    return this.value;
                },
                useHTML: true
            },
            title: {text: graph.attr("data-x-axis-text")}
        },
        yAxis: {
            title: {text: graph.attr("data-y-axis-text")}
        },
        tooltip: {
            formatter: function () {
                let x = this.series.name;
                if (typeof this.x === 'object' && this.x.x !== undefined) {
                    x = this.x.x;
                }
                return '<span class="graph-tooltip-x">' + x + '</span><br><span style="color:' + this.series.chart.options.colors[this.point.colorIndex] + ';" class="graph-tooltip-y-dot">● </span><span class="graph-tooltip-y"><b>' + this.point.series.yAxis.axisTitle.textStr + ': ' + this.y + '</b></span>';
            }
        },
        plotOptions: {
            column: {
                events: {
                    click: function () {
                        if (this.options.url !== undefined && !isMobile()) {
                            document.location.href = this.options.url;
                        }
                    }
                }
            }
        },
        credits: {enabled: false},
        series: [{
            showInLegend: false,
            data: primalData.ticket,
            name: graph.attr("data-y-axis-text")
        }]
    });
}

function highchartsBarStackedChart(primalData, graphId) {
    let graph = $("#" + graphId);

    Highcharts.chart(graphId, {
        chart: {type: 'column', styledMode: true, showAxes: true},
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        'viewFullscreen',
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                        'separator',
                        'downloadCSV',
                        'downloadXLS'
                    ]
                }
            }
        },
        title: false,
        xAxis: {
            categories: primalData.date,
            title: {text: graph.attr("data-x-axis-text")}
        },
        yAxis: {
            title: {text: graph.attr("data-y-axis-text")},
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                },
                events: {
                    click: function () {
                        if (this.options.url !== undefined && !isMobile()) {
                            document.location.href = this.options.url;
                        }
                    }
                }
            }
        },
        credits: {enabled: false},
        series: primalData.ticket
    });

}

function countDecimals(value) {
    let text = value.toString()
    if (text.indexOf('e-') > -1) {
        let [base, trail] = text.split('e-');
        return parseInt(trail, 10);
    }
    if (Math.floor(value) !== value) {
        return value.toString().split(".")[1].length || 0;
    }
    return 0;
}

function highchartsLineChart(primalData, graphId) {
    let graph = $("#" + graphId);
    let cumulativeData = [0];
    primalData.ticket.forEach(function (elementToAdd, index) {
        let newElement;
        if (typeof elementToAdd === 'object') {
            newElement = cumulativeData[index] + elementToAdd.y * 1;
        } else {
            newElement = cumulativeData[index] + elementToAdd * 1;
        }

        if (countDecimals(newElement) > 2) {
            newElement = Number(newElement).toFixed(2) * 1;
        }

        cumulativeData.push(newElement);
    });
    cumulativeData.shift();

    Highcharts.chart(graphId, {
        chart: {type: 'area', styledMode: true, showAxes: true},
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        'viewFullscreen',
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                        'separator',
                        'downloadCSV',
                        'downloadXLS'
                    ]
                }
            }
        },
        title: false,
        xAxis: {
            categories: primalData.date,
            labels: {
                formatter: function () {
                    return this.value;
                },
                useHTML: true
            },
            title: {text: graph.attr("data-x-axis-text")}
        },
        yAxis: {
            title: {text: graph.attr("data-y-axis-accumulated-text")}
        },
        tooltip: {
            formatter: function () {
                let x = this.series.name;
                if (typeof this.x === 'object' && this.x.x !== undefined) {
                    x = this.x.x;
                }
                return '<span class="graph-tooltip-x">' + x + '</span><br><span style="color:' + this.series.chart.options.colors[this.point.colorIndex] + ';" class="graph-tooltip-y-dot">● </span><span class="graph-tooltip-y"><b>' + this.point.series.yAxis.axisTitle.textStr + ': ' + this.y + '</b></span>';
            }
        },
        plotOptions: {
            area: {
                events: {
                    click: function () {
                        if (this.options.url !== undefined && !isMobile()) {
                            document.location.href = this.options.url;
                        }
                    }
                }
            }
        },
        credits: {enabled: false},
        series: [{
            showInLegend: false,
            data: cumulativeData,
            name: graph.attr("data-y-axis-accumulated-text")
        }]
    });
}

function highchartsLineChartAccumulated(primalData, graphId) {
    let graph = $("#" + graphId);
    for (let key in primalData.ticket) {
        if (primalData.ticket.hasOwnProperty(key)) {
            let cumulativeData = [0];
            primalData.ticket[key].data.forEach(function (elementToAdd, index) {
                let newElement = cumulativeData[index] + elementToAdd * 1;
                if (countDecimals(newElement) > 2) {
                    newElement = Number(newElement).toFixed(2) * 1;
                }
                cumulativeData.push(newElement);
            });
            cumulativeData.shift();
            primalData.ticket[key].data = cumulativeData;
        }
    }

    Highcharts.chart(graphId, {
        chart: {type: 'area', styledMode: true, showAxes: true},
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        'viewFullscreen',
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                        'separator',
                        'downloadCSV',
                        'downloadXLS'
                    ]
                }
            }
        },
        title: false,
        xAxis: {
            categories: primalData.date,
            title: {text: graph.attr("data-x-axis-text")}
        },
        yAxis: {
            title: {text: graph.attr("data-y-axis-accumulated-text")}
        },
        credits: {enabled: false},
        plotOptions: {
            area: {
                stacking: 'normal',
                lineColor: '#666666',
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: '#666666'
                },
                events: {
                    click: function () {
                        if (this.options.url !== undefined && !isMobile()) {
                            document.location.href = this.options.url;
                        }
                    }
                }
            }
        },
        series: primalData.ticket
    });
}

function highchartsPieChart(primalData, graphId) {
    let title = false;
    if (primalData.title !== undefined) {
        title = {
            text: primalData.title
        }
        delete primalData.title;
    }
    Highcharts.chart(graphId, {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            styledMode: true
        },
        exporting: {
            filename: 'chart',
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG', 'downloadSVG', 'downloadCSV', 'downloadXLS']
                }
            }
        },
        title: title,
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y:.2f}</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: false,
                events: {
                    click: function (event) {
                        if (event.point.url !== undefined && !isMobile()) {
                            document.location.href = event.point.url;
                        }
                    }
                }
            }
        },
        credits: {enabled: false},
        series: [primalData]
    });
}